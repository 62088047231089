import { createRouter, createWebHistory } from "vue-router"; 
import landingPage from '@/components/landingPage.vue';
import useAuthUser from "@/lib/SupaAuth"; // Zorg ervoor dat deze import correct is

const routes = [
    {
        path: '/',
        name: 'landingPage',
        component: landingPage,
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/components/signup/signupFile.vue'),
    },
    {
        path: '/EmailConfirmation',
        name: 'EmailConfirmation',
        component: () => import('@/components/signup/emailConfig.vue'),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/components/loginFile.vue"),
    },
    {   
        path: "/forgotPassword",
        name: "forgotPassword",
        component: () => import("@/components/forgotpassword/forgotPassword.vue"),
    },
    {   
        path: "/setnewpassword",
        name: "setnewpassword",
        component: () => import("@/components/forgotpassword/setnewPassword.vue"),
    },
    {   
        path: "/overview",
        name: "overview",
        component: () => import("@/components/overView.vue"),
        meta: { requiresAuth: true },
    },
    {   
        path: "/profile",
        name: "profile",
        component: () => import("@/components/profile/userProfile.vue"),
        meta: { requiresAuth: true },
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    const { isLoggedIn } = useAuthUser();
    console.log("Navigating to:", to.name);

    if (to.meta.requiresAuth) {
        const loggedIn = await isLoggedIn(); // Wacht op de async check
        console.log("User logged in:", loggedIn);

        if (!loggedIn) {
            console.log("Redirecting to login");
            return next({ name: "login" });
        }
    }
  
    next();
});
export default router;
