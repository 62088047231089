 <template>
  <div id='landingBG' class="w-dvw h-dvh grid place-items-center pt-20">
  

    <div class=" rounded-lg  bg-beige h-[180px] md:h-[18vh] w-[37vh] md:w-[45vh] p-5 shadow-black shadow-lg grid text-center"> 

     <p class="text-brown font-bold text-2xl">Create account to get early access</p>
      <button class="text-brown border-brown border-dotted hover:border-solid border-2 hover:bg-warmGeel transition duration-300 p-2 rounded-lg my-3">
        <router-link to="/signup" class="text-xl">Create Account</router-link>
      </button>
    </div>

    
  </div>
</template>

<script setup>

import { onMounted } from 'vue';


onMounted(() => {
    console.log('onMounted');
})

</script>

